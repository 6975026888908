export function Lenguaje({lenguaje, show_url = true}) {
    return (
        <div className={"col-span-1 flex flex-col justify-center items-center transition duration-300 ease-in-out hover:scale-105"}>
            <a href={lenguaje.link} target="_blank" rel="noreferrer">
                <img src={lenguaje.image} alt="linux" width="40" height="40"/>
            </a>

            <p className={`hidden ${show_url && "md:block"} flex-grow font-light text-sm leading-relaxed`}>{lenguaje.link.match(/(https?:\/\/)?([^\/\s]+)/)[2]}</p>
        </div>
    )
}

export default function Lenguajes({easter_egg, setEasterEgg, lenguajes}) {
    return (
        <div id={"lenguajes"} className={"flex flex-col justify-evenly gap-10 min-h-screen"}>
            <h1 className={"tracking-wider text-4xl font-bold w-fit px-6 xl:px-36"}>
                Mis lenguajes
            </h1>

            <div className={`grid grid-cols-3 auto-rows-fr gap-x-6 gap-y-10 xl:gap-y-28 px-6 xl:px-36 ${easter_egg >= 10 && "animate-ping"}`}>
                {
                    lenguajes
                        .map(
                            (lenguaje) => <Lenguaje lenguaje={lenguaje}/>
                        )
                }
            </div>
        </div>
    )
}