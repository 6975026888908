export default function Logo({easter_egg, setEasterEgg}) {
    return (
        <div className={`flex justify-center items-center h-screen ${easter_egg >= 10 && "animate-pulse"}`}>
            <svg
                width="512"
                height="512"
                viewBox="0 0 512 512"
                version="1.1"
                id="svg1"
                xmlns="http://www.w3.org/2000/svg">
                <defs
                    id="defs1"/>
                <g
                    id="layer1">
                    <path
                        id="path96"
                        className={"fill-lightmode-decoration stroke-lightmode-decoration stroke-[5.29439] transition-colors duration-700 ease-linear"}
                        d="M 225.39527,6.6972344 188.85306,143.4365 l -25.65954,9.9e-4 v 3.41082 l 23.9379,-6e-5 -23.9379,87.6695 31.22052,-87.6695 h 23.86488 l 7.11635,223.82472 V 146.84825 h 36.1308 24.90577 l -60.85936,222.91224 53.12888,-149.19642 9.06992,285.35184 V 146.84825 l 61.0352,9.8e-4 v -3.41177 l -123.41069,-9.6e-4 z m -9.07209,78.6238866 1.75447,58.115379 h -21.41692 z"/>
                </g>
            </svg>
        </div>
    )
}