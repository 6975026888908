import {useEffect, useState} from "react"

import Navbar from "./components/Navbar"
import Logo from './components/Logo'
import Proyectos from "./components/Proyecto"
import Darkmode from "./components/Darkmode"
import Lenguajes from "./components/Lenguajes"
import Footer from "./components/Footer"
import Sobremi from "./components/Sobremi";

const fetch_proyectos = async () => {
    const
        adriantoral = await fetch('https://api.github.com/users/adriantoral/repos'),
        tory1103 = await fetch('https://api.github.com/users/tory1103/repos')

    return [...await adriantoral.json(), ...await tory1103.json()].sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at)).reverse()
}

const fetch_lenguajes = async () => {
    return [
        {
            "link": " https://aws.amazon.com",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
        },
        {
            "link": "https://www.gnu.org/software/bash/",
            "image": "https://www.vectorlogo.zone/logos/gnu_bash/gnu_bash-icon.svg",
        },
        {
            "link": "https://getbootstrap.com",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-plain-wordmark.svg",
        },
        {
            "link": "https://www.cprogramming.com/",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/c/c-original.svg",
        },
        {
            "link": "https://www.w3schools.com/cpp/",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/cplusplus/cplusplus-original.svg",
        },
        {
            "link": "https://www.w3schools.com/css/",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg",
        },
        {
            "link": "https://www.docker.com/",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
        },
        {
            "link": "https://expressjs.com",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original-wordmark.svg",
        },
        {
            "link": "https://flask.palletsprojects.com/",
            "image": "https://www.vectorlogo.zone/logos/pocoo_flask/pocoo_flask-icon.svg",
        },
        {
            "link": "https://git-scm.com/",
            "image": "https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg",
        },
        {
            "link": "https://golang.org",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/go/go-original.svg",
        },
        {
            "link": "https://www.w3.org/html/",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg",
        },
        {
            "link": "https://www.java.com",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
        },
        {
            "link": "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
        },
        {
            "link": "https://kotlinlang.org",
            "image": "https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg",
        },
        {
            "link": "https://kubernetes.io",
            "image": "https://www.vectorlogo.zone/logos/kubernetes/kubernetes-icon.svg",
        },
        {
            "link": "https://www.linux.org/",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/linux/linux-original.svg",
        },
        {
            "link": "https://www.mongodb.com/",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
        },
        {
            "link": "https://www.mysql.com/",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
        },
        {
            "link": "https://nextjs.org/",
            "image": "https://cdn.worldvectorlogo.com/logos/nextjs-2.svg",
        },
        {
            "link": "https://nodejs.org",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg",
        },
        {
            "link": "https://www.python.org",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
        },
        {
            "link": "https://reactjs.org/",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg",
        },
        {
            "link": "https://redis.io",
            "image": "https://raw.githubusercontent.com/devicons/devicon/master/icons/redis/redis-original-wordmark.svg",
        },
        {
            "link": "https://www.sqlite.org/",
            "image": "https://www.vectorlogo.zone/logos/sqlite/sqlite-icon.svg",
        }
    ]
}

export default function App() {
    const
        [proyectos, setProyectos] = useState([]),
        [lenguajes, setLenguajes] = useState([]),
        [darkmode, setDarkmode] = useState(true),
        [easter_egg, setEasterEgg] = useState(0)

    useEffect(() => {
        fetch_proyectos().then(data => setProyectos(data))
        fetch_lenguajes().then(data => setLenguajes(data))
    }, [])

    useEffect(() => {
        document.body.className = darkmode ? 'dark' : ''
    }, [darkmode])

    return (
        <div className="font-text bg-lightmode dark:bg-darkmode text-darkmode dark:text-lightmode [transition:background_700ms] ease-linear">
            <Navbar easter_egg={easter_egg} setEasterEgg={setEasterEgg}/>

            <Logo easter_egg={easter_egg} setEasterEgg={setEasterEgg}/>

            <Sobremi easter_egg={easter_egg} setEasterEgg={setEasterEgg}/>

            <Lenguajes easter_egg={easter_egg} setEasterEgg={setEasterEgg} lenguajes={lenguajes}/>

            <Darkmode darkmode={darkmode} setDarkmode={setDarkmode} easter_egg={easter_egg} setEasterEgg={setEasterEgg}/>

            <Proyectos easter_egg={easter_egg} setEasterEgg={setEasterEgg} proyectos={proyectos}/>

            <Footer easter_egg={easter_egg} setEasterEgg={setEasterEgg}/>
        </div>
    )
}