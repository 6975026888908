export default function Sobremi({easter_egg, setEasterEgg}) {
    return (
        <div id={"sobre-mi"} className={`${easter_egg >= 10 && "hidden"} grid grid-cols-6 min-h-screen`}>
            <div className={"order-2 xl:order-1 col-span-6 xl:col-span-3 flex flex-col gap-6 justify-center items-start text-start px-6 xl:px-36"}>
                <h1 className={"font-header tracking-wider text-4xl font-bold"}>Quien soy</h1>
                <p className={"tracking-widest leading-relaxed break-normal"}>
                    Entusiasta de la programación con ganas de aprender y trabajar. <br/><br/>
                    Estudiante de 3º año en U-TAD esperando terminar en 2025. <br/><br/>
                    Llevo más de 5 años de desarrollo personal aprendiendo nuevas tecnologías y mejorando mis habilidades técnicas como programador. <br/><br/>
                </p>
            </div>
            <div className={"order-1 xl:order-2 col-span-6 xl:col-span-3 flex justify-center items-center"}>
                <img className={"rounded h-1/2"} src={"/quiensoy.png"} alt={"quiensoy.png"}/>
            </div>

            <hr className={"order-3 col-span-6"}/>

            <div className={"order-4 col-span-6 xl:col-span-3 flex justify-center items-center"}>
                <img className={"rounded h-1/2"} src={"/conocimientos.png"} alt={"conocimientos.png"}/>
            </div>
            <div className={"order-5 col-span-6 xl:col-span-3 flex flex-col gap-6 justify-center items-end text-end xl:text-start px-6 xl:px-36"}>
                <h1 className={"font-header tracking-wider text-4xl font-bold"}>Mis conocimientos</h1>
                <p className={"tracking-widest leading-relaxed break-normal"}>
                    De todo lo aprendido por mi cuenta destaco Python, bases de datos, C/C++, Docker, aplicaciones web entre otras. <br/><br/>
                    No tengo experiencia laboral en el sector, pero he aprendido a trabajar en equipo
                    y a colaborar en la comunidad de código abierto “open source”, me adapto fácilmente. <br/><br/>
                </p>
            </div>

            <hr className={"order-6 col-span-6"}/>

            <div className={"order-8 xl:order-7 col-span-6 xl:col-span-3 flex flex-col gap-6 justify-center items-start text-start px-6 xl:px-36"}>
                <h1 className={"font-header tracking-wider text-4xl font-bold"}>Informacion adicional</h1>
                <p className={"tracking-widest leading-relaxed break-normal"}>
                    Beca de excelencia el primer curso de carrera, matrícula de honor en programación en C y sistemas distribuidos. <br/><br/>
                    Nota media de la carrera de 8. <br/><br/>
                    He jugado futbol 11 regional federado en la sierra de Madrid en el equipo de El Escorial por más de 15 años. <br/><br/>
                </p>
            </div>
            <div className={"order-7 xl:order-8 col-span-6 xl:col-span-3 flex justify-center items-center"}>
                <img className={"rounded h-1/2"} src={"/informacionadicional.png"} alt={"informacionadicional.png"}/>
            </div>
        </div>
    )
}